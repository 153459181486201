.content-grid-wrapper {
  margin: 1.5em 0;
  padding: 0;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.content-grid {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.content-grid>* {
  grid-column: wide;
}

@media (min-width: 1200px) {
  .content-grid {
    display: grid;
    column-gap: var(--column-gap);
    grid-template-columns:
      [full-start wide-start] 2fr
      [medium-start] 1fr 
      [main-start main-left-start] 3fr
      [main-left-end main-right-start] 3fr 
      [main-right-end main-end] 1fr 
      [medium-end] 2fr 
      [wide-end full-end];
  }
}

@media (min-width: 1400px) {
  .content-grid {
    max-width: 1400px;
    grid-template-columns:
      [full-start] 1fr 
      [wide-start] 1fr 
      [medium-start] 1fr 
      [main-start main-left-start] 3fr
      [main-left-end main-right-start] 3fr
      [main-right-end main-end] 1fr
      [medium-end] 1fr 
      [wide-end] 1fr 
      [full-end];
  }
}

@media (min-width: 1600px) {
  .content-grid {
    max-width: 1600px;
  }
}
