pc-feed {
  display: block;
}

pc-feed .items {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 0.75em;
}

pc-feed .items > .item {
  display: flex;
  flex-direction: row;
  gap: 1em;
}

pc-feed .items > .item > time {
  display: block;
  text-align: right;
  white-space: nowrap;
  min-width: 14em;
}

pc-feed .items > .item > time::before {
  content: "\1F4C6";
  font-size: 0.8em;
  padding-right: 0.25em;
}
