.pc-tab-group {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: [tabs-start] auto [tabs-end content-start] 1fr [content-end];
}

.pc-tab-group details {
  display: contents;
}

.pc-tab-group details summary {
  grid-row: tabs;
  user-select: none;
  cursor: pointer;
  list-style: none;
  padding: 0.5em 0.25em 0.25em 0.25em;
  margin: 0 0.25em;
  text-align: center;
  border: 1px solid var(--theme-highlighted-bg-color);
  border-start-start-radius: 0.5em;
  border-start-end-radius: 0.5em;
}

.pc-tab-group details summary::-webkit-details-marker {
  display: none;
}

.pc-tab-group details[open] summary {
  background-color: var(--theme-highlighted-bg-color);
}

.pc-tab-group details summary + section {
  border-top: 1px solid var(--theme-highlighted-bg-color);
  margin: -1px 0 0 0;
  padding: 0 var(--column-gap);
  grid-column-start: 1;
  grid-column-end: -1;
  grid-row: content;
}

.pc-tab-group.vertical {
  display: grid;
  grid-template-columns: [tabs] var(--thumbnail-size) [content-start] auto [content-end];
  grid-template-rows: auto
}

.pc-tab-group.vertical details summary {
  grid-row: unset;
  grid-column: tabs;
  padding: 0.5em;
  margin: 0 0 0.25em 0;
  text-align: right;
  border-start-start-radius: unset;
  border-start-end-radius: unset;
  border-start-start-radius: 0.5em;
  border-end-start-radius: 0.5em;
}

.pc-tab-group.vertical details summary + section {
  border-top: unset;
  border-left: 1px solid var(--theme-highlighted-bg-color);
  margin: 0 0 0 -1px;
  grid-column: content / -1;
  grid-row: 1 / 10;
  display: block;
}
