pc-bookmark-form .unfurl-data summary {
  cursor: pointer;
  line-height: 1.5em;
  margin-bottom: 0.5em;
}

pc-bookmark-form .unfurl-data summary button.refresh {
  font-size: 0.8em;
  margin-left: 0.5em;
}

pc-bookmark-form .unfurl-data textarea {
  width: 100%;
  height: 10em;
  margin: 0.25em;
}
