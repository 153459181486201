/* adapted from https://codepen.io/alvarotrigo/pen/zYPydpB */
theme-selector {
  --light: #d8dbe0;
  --dark: #28292c;
  --switch-size: 1.25em;

  display: block;
  position: relative;
  height: var(--switch-size);
  width: calc(var(--switch-size) * 2);
  margin: 0;
  padding: 0;
}

theme-selector label {
  position: absolute;
  width: 100%;
  height: var(--switch-size);
  background-color: var(--dark);
  border-radius: calc(var(--switch-size) / 2);
  cursor: pointer;
}

theme-selector input {
  position: absolute;
  display: none;
}

theme-selector .slider {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: calc(var(--switch-size) / 2);
  transition: 0.3s;
  background-color: var(--dark);
}

theme-selector input:checked~.slider {
  background-color: var(--light);
}

theme-selector .slider::before {
  content: "";
  position: absolute;
  top: calc(var(--switch-size) * 0.13);
  left: calc(var(--switch-size) * 0.16);
  width: calc(var(--switch-size) * 0.75);
  height: calc(var(--switch-size) * 0.75);
  border-radius: 50%;
  /* moon crescent */
  box-shadow: inset calc(var(--switch-size) * 0.28) calc(var(--switch-size) * -0.04) 0px 0px var(--light);
  /* moon obscured */
  background-color: var(--dark);
  transition: 0.3s;
}

theme-selector input:checked~.slider::before {
  transform: translateX(var(--switch-size));
  /* sun shining */
  background-color: var(--dark);
  background-color: rgba(255, 191, 0, 1.0);
  box-shadow: none;
}
